<template>
    <div>
        <header>
            <div class="title">搭配选品</div>
            <div class="exp">筛选</div>
        </header>

        <div class="filters">

            <div class="filter-group">

                <div class="filter-item left-item">
                    <div class="filter-title">平台</div>
                    <div class="filter-content">
                        <a-radio-group v-model="query.driver" @change="driverChanged">
                            <template v-for="(plate,pk) in drivers">
                                <a-radio :key="pk" :value="plate.driver">
                                    <span class="filter-text">{{ plate.name }}</span>
                                </a-radio>
                            </template>
                        </a-radio-group>
                    </div>
                </div>


                <div class="filter-item">
                    <div class="filter-title">一级类目</div>
                    <div class="filter-content">
                        <a-radio-group v-model="query.cate1">
                            <a-radio key="all" value="all">
                                <span class="filter-text">全部</span>
                            </a-radio>
                            <template v-for="(category,ck) in driver.categories ">

                                <a-radio :key="ck" :value="category.id">
                                    <span class="filter-text">{{ category.name }}</span>
                                </a-radio>

                            </template>
                        </a-radio-group>
                    </div>
                </div>
            </div>


            <div class="filter-group">
                <div class="filter-item left-item">
                    <div class="filter-title">价格</div>
                    <div class="filter-content input-group">
                        <at-input v-model="query.priceFrom" unit="￥" placeholder="最低价格"></at-input>
                        <at-input v-model="query.priceTo" unit="￥" placeholder="最高价格"></at-input>
                    </div>
                </div>

                <div class="filter-item" style="margin-right: 22px">
                    <div class="filter-title">最低佣金</div>
                    <div class="filter-content">
                        <at-input v-model="localFilter.commission" unit="￥" placeholder="佣金"></at-input>
                    </div>
                </div>

                <div class="filter-item">
                    <div class="filter-title">最低销量</div>
                    <div class="filter-content">
                        <at-input v-model="localFilter.sale_num" placeholder="销量"></at-input>
                    </div>
                </div>
            </div>

            <div class="filter-group">
                <div class="filter-item left-item">
                    <div class="filter-title">关键词</div>
                    <div class="filter-content">
                        <at-input v-model="query.keywords" width="300px" placeholder="关键词"></at-input>
                    </div>
                </div>
                <div>
                    <button @click="search" class="btn btn-search">搜索</button>
                </div>
            </div>

        </div>

        <div class="content">

            <div class="content-header">

                <div class="sorts">

                    <a-radio-group v-model=" query.orderBy ">

                        <a-radio-button class="btn-sort" value="normal">综合</a-radio-button>

                        <template v-for="(label,value) in driver.orderItems ">
                            <a-radio-button class="btn-sort" :key="value" :value="value">
                                {{ label }}
                            </a-radio-button>
                        </template>

                    </a-radio-group>

                </div>

                <div class="mode">
                    <a-radio-group v-model="thumbMode" default-value="thumb" button-style="solid">
                        <a-radio-button class="btn-sort" value="white_image">
                            透明图
                        </a-radio-button>
                        <a-radio-button class="btn-sort" value="thumb">
                            电商图
                        </a-radio-button>
                        <a-radio-button class="btn-sort" value="multi-map">
                            多图模式
                        </a-radio-button>
                    </a-radio-group>
                </div>

            </div>

            <div class="goods-list">

                <div v-if="loading" class="loading">
                    <loader/>
                </div>
                <div v-else-if="errMsg" class="loading">{{ errMsg }}</div>
                <div v-else-if="!goods.length" class="loading"> - 没有发现符合条件的商品 -</div>
                <template v-else>

                    <template v-for="(item,jk) in goods">

                        <div class="goods-item" :key="jk">

                            <goods-card
                                    :item="item"
                                    :thumb-type="thumbMode"
                                    is-third-goods
                                    editable
                                    @whiteImageChangedAndClose="whiteImageChangedAndClose"
                            ></goods-card>


                            <button @click="da(item)" class="btn-da"
                                    :class="{selected: selectedGoods.indexOf(item) >= 0 }">选
                            </button>

                        </div>

                    </template>

                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>

                </template>

            </div>

        </div>

        <a-affix :offset-bottom="0" v-if="selectedGoods.length || daing">
            <div class="scheme-area">
                <div class="area-title">搭配区</div>
                <div class="area-content">
                    <div class="selected-goods">

                        <div v-if="daing" class="selected-goods-item">
                            <loader/>
                            <div class="text-center">透明图检测中</div>
                        </div>

                        <template v-for="(goods,sk) in selectedGoods">

                            <div :key="sk" class="selected-goods-item">

                                <img :src="goods.white_image" alt="">

                                <div @click="delSelectedGoods(goods)" class="del-selected-btn"></div>
                            </div>

                        </template>

                    </div>
                    <div class="btn-area">
                        <button @click="confirmScheme(0)" class="btn btn-white">暂时入库</button>

                        <button @click="confirmScheme(1)" class="btn">开始搭配</button>
                    </div>
                </div>
            </div>
        </a-affix>

    </div>
</template>

<script>
import api from '@/repo/api';
import AtInput from "../../../components/AtInput";
import Loader from "../../../components/Loader";
import GoodsCard from "./GoodsCard";
import Vue from 'vue';

export default {
    name: "Picker",
    components: {GoodsCard, Loader, AtInput},
    data() {
        return {
            config: [],
            drivers: [],
            thumbMode: 'white_image',
            driver: {
                categories: []
            },
            query: {
                driver: 'jd',
                cate1: 'all',
                orderBy: 'normal',
                priceFrom: '',
                priceTo: '',
                keywords: '',
            },
            localFilter: {
                commission: '',
                sale_num: '',
            },
            pager: {
                no: 1,
                total: 0,
                size: 10,
                current: 1,
            },
            loading: false,
            errMsg: '',
            goods: [],
            baseGoods: [],
            daing: false,
            selectedGoods: [],
            goodsError: '',
        }
    },
    mounted() {
        this.getConfig()
    },
    methods: {
        search() {
            this.loading = true
            this.errMsg = ''

            let query = JSON.parse(JSON.stringify(this.query))

            api.get('/ast-ec/goods', query, (data) => {

                this.loading = false

                if (data.code === 0) {

                    this.baseGoods = data.data.data
                    this.pager.current = data.data.current_page
                    this.pager.total = data.data.total
                    this.pager.size = data.data.per_page

                    this.localFilterGoods()

                } else {

                    this.errMsg = data.msg

                }

            });
        },
        localFilterGoods() {

            if ((!this.localFilter.commission) && (!this.localFilter.sale_num)) {
                this.goods = [].concat(this.baseGoods)

                return
            }

            let arr = []

            this.baseGoods.forEach(goods => {

                let shouldPush = true;

                if (this.localFilter.commission && parseFloat(goods.commission) < this.localFilter.commission) {
                    shouldPush = false
                }

                if (this.localFilter.sale_num && parseFloat(goods.sale_num) < this.localFilter.sale_num) {
                    shouldPush = false
                }

                if (shouldPush) arr.push(goods);
            })

            this.goods = arr
        },
        otherChanged(e) {
            console.log(e);
        },
        getConfig() {

            api.get('/ast-ec/config', (data) => {

                this.drivers = data.data
                this.driverChanged()

            });

        },
        driverChanged() {

            this.query.cate1 = 'all';
            this.query.orderBy = 'normal';

            this.drivers.forEach(driver => {
                if (driver.driver === this.query.driver) this.driver = driver
            })
        },
        whiteImageChangedAndClose(goods, image) {

            delete goods.error

            Vue.set(goods, 'white_image', image)
            this.selectedGoods.unshift(goods)

            setTimeout(() => {
                document.body.style.overflowY = 'auto'
            }, 500)
        },
        da(goods) {

            let idx = this.selectedGoods.indexOf(goods)

            if (idx >= 0) {
                this.selectedGoods.splice(idx, 1)
                return
            }

            if (goods.white_image) {

                delete goods.error

                this.selectedGoods.unshift(goods)

                return
            }

            Vue.set(goods, 'error', '此商品没有白底图，请人工裁剪抠图生成白底')
        },
        delSelectedGoods(goods) {
            let idx = this.selectedGoods.indexOf(goods)
            if (idx < 0) return

            this.selectedGoods.splice(idx, 1)
        },
        confirmScheme(toScheme) {

            if (!this.selectedGoods.length) return;

            this.$loading.show()

            api.post('/ast-ec/save-goods', {goods: this.selectedGoods}, (data) => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code === 0) {

                    this.selectedGoods = []


                    if (toScheme) {

                        let ids = data.data.ids.join(',')

                        window.open('/dps/deck?goods=' + ids)
                    }
                }

            });


        }
    }
}
</script>

<style lang="less" scoped>
.selected-goods-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 128px;
    height: 128px;
    background: #F5F5F5;
    padding: 13px;
    overflow: hidden;
    margin-right: 16px;
    position: relative;
    font-size: 12px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.scheme-area {
    width: 100%;
    height: 200px;
    padding: 16px 24px;
    border: 1px solid #EEEEEE;
    background-color: #ffffff;

    .area-title {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-bottom: 16px;
    }

    .area-content {
        height: 128px;
        display: flex;
        justify-content: flex-start;
    }

    .selected-goods {
        flex: 1;
        display: flex;
        justify-content: flex-start;
    }

    .btn-area {
        width: 88px;
        position: relative;

        .btn {
            width: 88px;
            height: 32px;
            background: #333333;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            padding: 0;
            position: absolute;
            bottom: 4px;
            left: 0;

            &:first-child {
                bottom: 52px;
            }
        }

        .btn-white {
            background: #FFFFFF;
            border: 1px solid #333333;
            color: #333333;
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.btn-sort {
    font-size: 14px;
    font-weight: 400;

    border: 1px solid #EEEEEE;
    margin-right: 16px;
    border-radius: 0;
    padding-left: 18px;
    padding-right: 18px;
    height: 32px;
    line-height: 32px;

    /deep/ span {
        color: #999999;
    }

    &:before {
        display: none;
    }

    &.ant-radio-button-wrapper-checked {
        background: #333333;
        border: 1px solid #333333;

        /deep/ span {
            color: #FFFFFF;
        }

        &:hover {
            background-color: #333333;
            border-color: #333333;
        }
    }
}

.mode .btn-sort {
    margin-right: 0;
    height: 36px;
    line-height: 36px;
}

.content {
    margin-top: 32px;
    border: 1px solid #EEEEEE;
    width: 100%;
}

.content-header {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
}

.filters {
    border: 1px solid #EEEEEE;
    margin-top: 40px;

}

.title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
}

.exp {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
}

header {
    display: flex;
    justify-content: space-between;
}

.filters {

    .filter-title {
        margin-bottom: 15px;
    }

    .filter-group {
        display: flex;
        position: relative;
        border-bottom: 1px solid #EEEEEE;
    }

    .btn-search {
        position: absolute;
        right: 24px;
        bottom: 24px;
    }

    .filter-item {
        padding: 24px;
    }

    /deep/ .ant-radio-wrapper {
        margin-right: 24px;

        .filter-text {
            color: #999999;
        }
    }

    /deep/ .ant-radio-wrapper-checked {

        .filter-text {
            color: #333333;
        }
    }

    .filter-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: inherit;
    }
}

.checkbox /deep/ span {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}

.left-item {
    width: 424px;
}

.input-group {
    display: flex;
    width: 330px;
    justify-content: space-between;
    flex-direction: row;
}

.filter-title {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
}

.goods-list {
    min-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px 44px;
    margin-right: -42px;
}

.goods-item {
    width: 200px;
    margin-right: 40px;
    margin-bottom: 40px;
    position: relative;
}

.btn-da {
    width: 24px;
    height: 24px;
    background: #333333;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    border: 0;
    border-radius: 50%;
    cursor: pointer;

    &.selected {
        color: #FFFFFF;
        background: #FFAD00;
    }
}


.del-selected-btn {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border: 1px solid #EEEEEE;
    border-radius: 50%;

    background: #FFFFFF url("/assets/icons/small-close.png") no-repeat center center;
    background-size: 6px;
    cursor: pointer;
}
</style>